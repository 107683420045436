<template>
  <b-card v-if="user">
    <validation-observer
      ref="mainValidation"
      tag="form"
    >
      <b-row>
        <b-container
          fluid
          class="mt-2"
        >
          <b-row>
            <b-col
              xl="4"
              md="12"
              sm="12"
            >
              <build-statistic-card
                icon="GiftIcon"
                color="info"
                :statistic="getBasicBuildCount()"
                statistic-title="Basic Builds Remaining"
              />
            </b-col>

            <b-col
              xl="4"
              md="12"
              sm="12"
            >
              <build-statistic-card
                icon="PackageIcon"
                color="success"
                :statistic="getStandardBuildCount()"
                statistic-title="Standard Builds Remaining"
              />
            </b-col>
            <b-col
              xl="4"
              md="12"
              sm="12"
            >
              <build-statistic-card
                icon="GridIcon"
                color="primary"
                :statistic="getPremiumBuildCount()"
                statistic-title="Premiun Builds Remaining"
              />
            </b-col>
          </b-row>
        </b-container>
        <b-col>
          <p class="text-center">
            Get Unlimited  Builds by Buying  Subscription
          </p>
          <div class="px-1">
            <div class="mt-2">
              <b-button
                  @click="getSubscriptions()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                :disabled="isLoading"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Buy Subscription
              </b-button>
            </div>
            <hr>
            <div class="mt-2">
              <span class="mt-3">Please Select the Build type</span>
            </div>
            <div>
              <small>Please note Basic Builds will display "Powered By WebToAppConvert.com" message on the splash screen. Use Standard/Premium Builds to hide this message</small>
            </div>

            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="selectedBuildType"
                name="some-radios"
                value="basic"
              >
                Basic Build
              </b-form-radio>
              <b-form-radio
                v-model="selectedBuildType"
                name="some-radios"
                value="standard"
              >
                Standard Build
              </b-form-radio>
              <b-form-radio
                v-model="selectedBuildType"
                name="some-radios"
                value="premium"
              >
                Premium Build
              </b-form-radio>

            </div>
            <hr>
            <div class="mt-2">
              <label
                for="sb-inline"
                class="mr-1"
              >Version Code</label>
              <b-form-spinbutton
                id="sb-inline"
                v-model="versionCode"
                min="1"
                inline
              />
            </div>
            <b-row class="mt-2">
              <b-col md="6">
                <b-form-group
                  label="Version Name"
                  label-for="mc-first-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Version Name"
                    rules="required|regex:^\d+(\.\d+)*$"
                  >
                    <b-form-input
                      id="mc-first-name"
                      v-model="versionName"
                      required
                      placeholder="1.0"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Package Name"
                  label-for="mc-last-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Package Name"
                    rules="required|regex:[\.]"
                  >
                    <b-form-input
                      id="mc-last-name"
                      v-model="packageName"
                      required
                      placeholder="com.sitename.app"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <hr>
            <div class="mt-2">
              <span class="mt-3">How do you like to sign your app bundle for release, Available options changes based on the selected build type above.</span>
            </div>

            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="selectedKeyType"
                name="key-radio"
                value="automatic"
              >
                Automatic
              </b-form-radio>
              <b-form-radio
                v-model="selectedKeyType"
                :disabled="selectedBuildType ==='basic'"
                name="key-radio"
                value="newKey"
              >
                Create New Key
              </b-form-radio>
              <b-form-radio
                v-model="selectedKeyType"
                :disabled="selectedBuildType ==='standard' || selectedBuildType ==='basic'"
                name="key-radio"
                value="customKey"
              >
                Upload Custom Key
              </b-form-radio>
            </div>

            <div
              v-if="selectedKeyType ==='newKey'"
              class="mt-2"
            >

              <div class="w-100">
                <validation-observer
                  ref="newKeyValidation"
                  class="w-100"
                  tag="form"
                >

                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Key Password"
                        label-for="mc-key-pwd"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Key Password"
                          rules="required"
                        >
                          <b-form-input
                            id="mc-key-pwd"
                            v-model="keyPassword"
                            required
                            placeholder="KeyPassword"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Keystore Password"
                        label-for="mc-keystore-pwd"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Keystore Password"
                          rules="required"
                        >
                          <b-form-input
                            id="mc-keystore-pwd"
                            v-model="keyStorePassword"
                            required
                            placeholder="Keystore Password"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Key Alias"
                        label-for="mc-alias"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Key alias"
                          rules="required"
                        >
                          <b-form-input
                            id="mc-alias"
                            v-model="keyAlias"
                            placeholder="Key alias"
                            :state="errors.length > 0 ? false:null"
                            required
                          />
                          <small class="text-danger">{{ errors[0] }}</small>

                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Locality"
                        label-for="mc-locality"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Locality"
                          rules="required"
                        >
                          <b-form-input
                            id="mc-locality"
                            v-model="locality"
                            placeholder="Locality"
                            :state="errors.length > 0 ? false:null"
                            required
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Country Code"
                        label-for="mc-key-pwd"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Country Code"
                          rules="required"
                        >
                          <b-form-input
                            id="mc-key-pwd"
                            v-model="countryCode"
                            required
                            maxlength="2"
                            placeholder="Country Code"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Name"
                        label-for="mc-name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                        >
                          <b-form-input
                            id="mc-name"
                            v-model="name"
                            placeholder="Name"
                            :state="errors.length > 0 ? false:null"
                            required
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Organization"
                        label-for="mc-organization"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Organization"
                          rules="required"
                        >
                          <b-form-input
                            id="mc-organization"
                            v-model="organization"
                            placeholder="Organization"
                            :state="errors.length > 0 ? false:null"
                            required
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Organization-unit"
                        label-for="mc-Organization-unit"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Organization-unit"
                          rules="required"
                        >
                          <div class="form-label-group">
                            <b-form-input
                              id="mc-organization-unit"
                              v-model="organizationUnit"
                              placeholder="Organization-unit"
                              :state="errors.length > 0 ? false:null"
                              required
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-for="mc-state"
                        label="State"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          rules="required"
                        >
                          <div class="form-label-group">
                            <b-form-input
                              id="mc-state"
                              v-model="state"
                              placeholder="State"
                              :state="errors.length > 0 ? false:null"
                              required
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </div>

            </div>

            <div
              v-if="selectedKeyType ==='customKey'"
              class="mt-2"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Upload Key File"
                    label-for="mc-Key-file"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Key File"
                      rules="required"
                    >
                      <b-form-file
                        v-model="file"
                        placeholder="Choose a key file or drop it here..."
                        drop-placeholder="Drop file here..."
                        :state="errors.length > 0 ? false:null"
                        required
                        accept=".jks"
                        @input="fileAdd($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Key Password"
                    label-for="mc-Key-pwd"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Key Password"
                      rules="required"
                    >
                      <b-form-input
                        id="mc-Key-pwd"
                        v-model="keyPassword"
                        placeholder="Key Password"
                        :state="errors.length > 0 ? false:null"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Key Alias"
                    label-for="mc-alias"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Key alias"
                      rules="required"
                    >
                      <b-form-input
                        id="mc-alias"
                        v-model="keyAlias"
                        placeholder="Key alias"
                        :state="errors.length > 0 ? false:null"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="keystore Password"
                    label-for="mc-keysotrPwd"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="keystore Password"
                      rules="required"
                    >
                      <b-form-input
                        id="mc-keysotrPwd"
                        v-model="keyStorePassword"
                        placeholder="keystore Password"
                        :state="errors.length > 0 ? false:null"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <hr>

          </div>

          <!-- submit and reset -->
          <b-col class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="!buildContinueEnable || isLoading"
              class="mr-1"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="onCancelClick()"
            >
              Cancel
            </b-button>

            <span
              v-if="!buildContinueEnable"
              class="pl-2  no-build-left-label"
            >You dont have any more {{ selectedBuildType }} build(s) left Please Select a different build type or <a :href="getBuyMoreUrl(selectedBuildType)">Buy More Builds</a></span>
          </b-col>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BSpinner,
  BFormFile,
  BFormInput,
  BFormGroup,
  BContainer,
  BFormRadio,
  BFormSpinbutton,

} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import BuildStatisticCard from './BuildStatisticCard.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
    BFormFile,
    BFormGroup,
    BRow,
    BCol,
    BFormRadio,
    BSpinner,
    BFormInput,
    BContainer,
    BuildStatisticCard,
    BFormSpinbutton,
  },
  data() {
    return {
      required,
      versionCode: 1,
      keyPassword: null,
      versionName: null,
      packageName: null,
      keyStorePassword: null,
      keyAlias: null,
      locality: null,
      name: null,
      organization: null,
      organizationUnit: null,
      state: null,
      isLoading: false,
      selectedKeyType: 'automatic',
      selectedBuildType: 'basic',
      file: null,
      countryCode: null,
      fileUploadedUrl: null,
    }
  },
  setup() {
    store.dispatch('auth/fetchUserProfileWithoutNav', store.getters['auth/userId'], { root: true })
      .then(() => {
      })

    store.dispatch('appSite/fetchSelectedApp', { id: router.currentRoute.params.id })
      .then(() => {
        // appData.value = store.getters['appSite/selectedApp']
      })
      .catch(error => {
        console.log(error)
      })
    return {
      avatarText,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    loadingStatus() {
      return this.$store.getters['loading/loadingStatus']
    },
    buildContinueEnable() {
      if (this.selectedBuildType === 'basic' && new Date(this.user.basicSubscriptionEndDate?.toDate()) > new Date()) {
        return true
      }
      if (this.selectedBuildType === 'standard' && new Date(this.user.standardSubscriptionEndDate?.toDate()) > new Date()) {
        return true
      }
      if (this.selectedBuildType === 'premium' && new Date(this.user.premiumSubscriptionEndDate?.toDate()) > new Date()) {
        return true
      }
      if (this.selectedBuildType === 'basic' && this.user.remainingBasicBuilds > 0) {
        return true
      }
      if (this.selectedBuildType === 'premium' && this.user.remainingPremiumBuilds > 0) {
        return true
      }
      if (this.selectedBuildType === 'standard' && this.user.remainingStandardBuilds > 0) {
        return true
      }
      return false
    },
  },
  watch: {
    loadingStatus(payload) {
      this.isLoading = payload.isLoading
    },
    selectedBuildType() {
      this.selectedKeyType = 'automatic'
    },
  },
  methods: {
    validationForm() {
      this.$refs.mainValidation.validate().then(success => {
        console.log(success)
        if (success) {
          this.showMsgBoxTwo()
        }
      })
    },
    fileAdd(file) {
      const re = /(\.jks)$/i
      if (!re.exec(file.name)) {
        // eslint-disable-next-line no-alert
        alert('File extension not supported!')

        // this.$store.dispatch('toaster/setMessage', {
        //   title: 'File Format Not Supported',
        //   icon: 'StopCircleIcon',
        //   variant: 'danger',
        // })
        this.file = null
        return
      }
      if (file) {
        // this.$store.dispatch('loading/startLoading')
        this.$store.dispatch('common/uploadNewKeyFile', file).then(url => {
          this.fileUploadedUrl = url
          // this.$store.dispatch('loading/stopLoading')
        })
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    buildClick() {
      this.$store.dispatch('appSite/startBuild', { buildType: this.selectedBuildType })
    },
    onCancelClick() {
      this.$router.push(`/view-app/${router.currentRoute.params.id}`)
    },
    showMsgBoxTwo() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to start the build with current configurations,this can not be reverted and available build count will be consumed', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes Start Build',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('appSite/startBuild', {
              buildType: this.selectedBuildType,
              keyType: this.selectedKeyType,
              basicData: {
                versionCode: this.versionCode,
                versionName: this.versionName,
                packageName: this.packageName,
              },
              keyData: {
                fileUploadedUrl: this.fileUploadedUrl,
                keyStorePassword: this.keyStorePassword,
                keyAlias: this.keyAlias,
                keyPassword: this.keyPassword,
                countryCode: this.countryCode,
                locality: this.locality,
                name: this.name,
                organization: this.organization,
                organizationUnit: this.organizationUnit,
                state: this.state,
              },
            })
          }
        })
    },
    getBasicBuildCount() {
      if (new Date(this.user.basicSubscriptionEndDate?.toDate()) > new Date()) {
        return '∞'
      }
      return this.user.remainingBasicBuilds
    },
    getStandardBuildCount() {
      if (new Date(this.user.standardSubscriptionEndDate?.toDate()) > new Date()) {
        return '∞'
      }
      return this.user.remainingStandardBuilds
    },
    getPremiumBuildCount() {
      if (new Date(this.user.premiumSubscriptionEndDate?.toDate()) > new Date()) {
        return '∞'
      }
      return this.user.remainingPremiumBuilds
    },
    getBuyMoreUrl(type) {
      return `../checkout?product=${type}`
    },
    getSubscriptions() {
      window.location.href = '../pricing?plan=monthly'
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'Build App',
  },
}
</script>

<style>
.no-build-left-label{
  color: #ff0000;
}
</style>
